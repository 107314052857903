window.loadGoogleMapsApiCalled = false;
window.mapsLoadedCalled = false;
window.mapsLoadedCallbacks = null;

window.loadGoogleMapsApi = function (callbackFunction) {
    if (!loadGoogleMapsApiCalled) {
        loadGoogleMapsApiCalled = true;

        var url = "https://maps.googleapis.com/maps/api/js?region=CH&libraries=geometry,places&callback=mapsLoaded";
        if (CONFIG.language !== null)
            url += "&language=" + CONFIG.language;
        if (CONFIG.googleMapsApiKey !== null)
            url += "&key=" + CONFIG.googleMapsApiKey;

        var script_tag = document.createElement('script');
        script_tag.type = 'text/javascript';
        script_tag.async = false;
        script_tag.defer = true;
        script_tag.src = url;
        document.body.appendChild(script_tag);
    }

    if (window.mapsLoadedCalled) {
        callbackFunction.call();
    } else {
        if (window.mapsLoadedCallbacks === null)
            window.mapsLoadedCallbacks = [];
        window.mapsLoadedCallbacks.push(callbackFunction);
    }

    window.mapsLoaded = function () {
        window.mapsLoadedCalled = true;
        if (window.mapsLoadedCallbacks !== null) {
            while (window.mapsLoadedCallbacks.length > 0)
                window.mapsLoadedCallbacks.pop().call();
        }
    };
};